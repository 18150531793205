// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery_rut")
require("cycle2")
require("fotorama/fotorama.js")
require("pgwslider.min.js")
require("channels")
// require("timepicker")

import './src/application.scss'
import toastr from 'toastr'
window.toastr = toastr

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
$(function(){
  $("input#tee_time_tee_time_players_attributes_0_rut").rut({validateOn: 'change'});

  // $(".rut").rut().on('rutInvalido', function(e) {
  //   if ($(this).val()) {
  // 	 $(this).css('border-color', 'red');
  //   }
  // });

  $('.logo_site').on('click', function(){
    window.location = "/"
  });

  $('#location').on('click', function() {
    $(this).val('');
  })
})
